"use strict";
function checkPatterns(form) {
    var success = true;
    $(form).find("input[pattern]").each(function() {
        if(!this.disabled && !this.value.match(new RegExp("^" + this.pattern + "$"))) {
            ShowNotification.fail(`${this.title}`);
            success = false;
        }
    });
    return success;
}

/**
 * @param {JQuery.jqXHR} jqXHR
 * @returns {string | false}
 */
function humanMessage(jqXHR) {
    if(
        jqXHR.responseJSON &&
        jqXHR.responseJSON.error &&
        jqXHR.responseJSON.error.data &&
        jqXHR.responseJSON.error.data.humanReadableMessage
    ) {
        return jqXHR.responseJSON.error.data.humanReadableMessage
    } else if(
        jqXHR.responseJSON &&
        jqXHR.responseJSON.humanReadableMessage
    ) {
        return jqXHR.responseJSON.humanReadableMessage
    } else {
        return false
    }
}

/**
 * Helper for validating passwords
 */
class PasswordValidator {
    /**
     * A single regular expression for these tests
     */
    static get comboPattern() {
        return new RegExp([
            ...Object.values(this.positive).map(
                pattern => `(?=.*${pattern.source})`
            ),
            ...Object.values(this.negative).map(
                pattern => `(?!.*${pattern.source})`
            ),
            ".*"
        ].join(""))
    }
    /**
     * A negative pattern map
     */
    static get negative() {
        return {
            controlCharacters: /[\u0000-\u0020]/,
        }
    }
    /** A name-pattern map */
    static get positive() {
        return {
            longEnough: /.{8}/,
            numbers: /\d/,
            /*
             * between space and 0-9;
             * between 0-9 and A-Z;
             * between A-Z and a-z;
             * between a-z and the top of the range (excluding x7f)
             */
            specialCharacters:
                /[\u0021-\u002f\u003a-\u0040\u005b-\u0060\u007b-\u007e\u0080-\uffff]/,
        }
    }
    /** The general reason why validation would fail */
    static get reason() {
        return translation.get("Passwords should be at least 8 characters long with some numeric and special characters")
    }
    /**
     * @param {string} password
     * @returns {PasswordValidator}
     */
    static forPassword(password) {
        return new this(password)
    }
    /**
     * @param {string} password
     */
    constructor(password) {
        this.password = password
    }
    /**
     * @type {typeof PasswordValidator}
     */
    get c() {
        //@ts-ignore
        return this.constructor
    }
    get failedTests() {
        return this.tests.filter(test => test.test())
    }
    get hasControlCharacters() {
        return !!this.password.match(this.c.negative.controlCharacters)
    }
    get hasNonNumeric() {
        return this.password.match(/\D/)
    }
    get hasNumbers() {
        return !!this.password.match(this.c.positive.numbers)
    }
    get hasSpecialCharacters() {
        return !!this.password.match(this.c.positive.specialCharacters)
    }
    get isLongEnough() {
        return !!this.password.match(this.c.positive.longEnough)
    }
    /**
     * Error messages. This is the second-most-used
     * property.
     */
    get notes() {
        return this.failedTests.map(test => test.message)
    }
    get tests() {
        return [
            {
                message: translation.get("Password contains invalid characters"),
                test: () => this.hasControlCharacters,
            },
            {
                message: translation.get("One digit or non alpha numeric character required"),
                test: () => !(this.hasSpecialCharacters || this.hasNumbers),
            },
            {
                message: translation.get("Length must be at least 8 characters"),
                test: () => !this.isLongEnough,
            },
            {
                message: translation.get("Your password must contain non-numeric characters."),
                test: () => !this.hasNonNumeric,
            }
        ]
    }
    /**
     * Whether the password is acceptable. This is the most-used property.
     */
    get valid() {
        return !this.failedTests.length
    }
}

/**
 * Helper for validating passwords (extended)
 */
class ExtendedPasswordValidator extends PasswordValidator {
    /** The general reason why validation would fail */
    static get reason() {
        return translation.get("Passwords should be at least 8 characters long using a mixture of upper/lower case letters with numeric and special characters")
    }
    /** A name-pattern map */
    static get positive() {
        return {
            ...super.positive,
            lowerCaseLetters: /[a-z]/,
            upperCaseLetters: /[A-Z]/,
        }
    }
    get hasLowerCaseLetters() {
        return !!this.password.match(this.c.positive.lowerCaseLetters)
    }
    get hasUpperCaseLetters() {
        return !!this.password.match(this.c.positive.upperCaseLetters)
    }
    get tests() {
        return [
            ...super.tests,
            {
                test: () => !(this.hasUpperCaseLetters && this.hasLowerCaseLetters),
                message: translation.get("Both upper-case and lower-case letters required"),
            },
        ]
    }
}

/**
 *
 * @param {string} password
 * @param {boolean} extended
 * @returns {PasswordValidator}
 */
function passwordValidator(password, extended = false) {
    // It should not be undefined but this prevents JS stopping
    if(typeof password != "string") password = ""
    return extended ?
        new ExtendedPasswordValidator(password) :
        new PasswordValidator(password)
}

/**
 * Simple check string looks like a domain
 *
 * @param {string} domain
 * @returns {boolean}
 */
function looks_like_domain(domain) {
    return !!(
        domain.match(/^[a-zA-Z0-9.-]*[a-zA-Z0-9][.][a-zA-Z0-9][a-zA-Z0-9.-]*$/)
    )
}

/**
 * Simple check string looks like an email address
 *
 * @param {string} address_in
 * @returns {boolean}
 */
function looks_like_emailaddress(address_in) {
    const addresses = address_in.startsWith(",") ?
        [address_in] : // Compat only, apparently not intended.
        address_in.split(/,/)
    return addresses.every(
        address =>
            address.match(/^[a-zA-Z0-9+\._-]+@/) &&
            looks_like_domain(address.replace(/^[^@]*@/, ""))
    )
}

/**
 * Clears the validation state for an element (as if valid).
 *
 * @param {JQuery<HTMLElement>} $el
 */
function clear_validation($el) {
    const $p = $el.parents(`[class^="field-group"]`)
    $p.find(".input-helper").hide()
    $p.removeClass("error")
    $el.closest(".invalid-feedback").hide()

    // Bootstrap validation
    $el.closest(".form-group").find(".invalid-feedback").hide()
}

/**
 * Validate a single element, and if it's invalid updates the UI. This should be
 * paired with clear_validation().
 *
 * @param {JQuery<HTMLElement>} $el
 * @returns {boolean}
 */
function maybe_mark_invalid($el) {
    const element = $el[0]
    if(
        element instanceof HTMLButtonElement ||
        element instanceof HTMLFieldSetElement ||
        element instanceof HTMLInputElement ||
        element instanceof HTMLObjectElement ||
        element instanceof HTMLOutputElement ||
        element instanceof HTMLSelectElement ||
        element instanceof HTMLTextAreaElement
    ) {
        element.classList.add("was-validated")
        const is_valid = element.checkValidity()
        if(!is_valid) {
            const text =
                $el.attr("title") ||
                element.validationMessage ||
                translation.get("This field is invalid")

            const $p = $el.parents(`[class^="field-group"]`)

            $p.addClass("error")

            if(!$p.find(".input-helper").length) {
                $p.append($("<div/>").addClass("input-helper"))
            }

            $p.find(".input-helper").last().show().text(text)

            // Bootstrap validation
            $el.closest(".form-group").find(".invalid-feedback").show()
            $el.closest(".form-group").find(".valid-feedback").hide()
        } else {
            if("value" in element && element.value.length) {
                $el.closest(".form-group").find(".valid-feedback").show()
            }
        }
        return is_valid
    } else {
        return true
    }
}

/**
 * Validate a single element, and show relevant error message and state.
 *
 * @see maybe_mark_invalid()
 *
 * @param {JQuery<HTMLElement>} $el
 * containing an element.
 * @returns {boolean}
 */
function validate_element($el) {
    clear_validation($el)
    return maybe_mark_invalid($el)
}

/**
 * Validate all inputs found within a jQuery element.
 *
 * @param {JQuery<HTMLElement>} $c A jQuery object in which all matching inputs
 * with required or pattern are validated.
 * @returns {boolean}
 */
function validate_inputs_in($c) {
    const $inputs = $c.find("[required], [pattern]")
    $inputs.each((i, e) => clear_validation($(e)))
    return !$inputs.filter(
        (i, e) => !maybe_mark_invalid($(e))
    ).length
}

function php_urlencode(str) {
    // Taken from http://locutus.io/php/url/urlencode/

    str = (str + '');

    // Tilde should be allowed unescaped in future versions of PHP (as reflected below),
    // but if you want to reflect current
    // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
    return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/%20/g, '+')
}

/**
 * @param {number} n
 * @return {string}
 */
function ordinal(n) {
    if(n > 10 && n < 20) {
        return `${n}th`
    } else {
        switch(n % 10) {
            case 1:
                return `${n}st`
            case 2:
                return `${n}nd`
            case 3:
                return `${n}rd`
            default:
                return `${n}th`
        }
    }
}

/**
 * Performs a lightweight param decode.
 *
 * @param {string} param_str eg. "foo=bar&baz"
 * @returns {{[x: string]: ?string}} eg. {foo: "bar", baz: null}
 */
function decode_query_params(param_str) {
    /** @type {{[x: string]: ?string}} */
    const query_params = {}
    let md
    if(md = param_str.match(/^[?](.+)$/)) {
        md[1].split(/[&;]/).forEach(item => {
            let mdi
            if(mdi = item.match(/^([^=]+)=(.*)/)) {
                query_params[decodeURIComponent(mdi[1])] =
                    decodeURIComponent(mdi[2])
            } else {
                query_params[decodeURIComponent(item)] = null
            }
        })
    }
    return query_params
}

/**
 * Performs a lightweight param decode.
 *
 * @param {{[x: string]: ?string}} params eg. {foo: "bar", baz: null}
 * @returns {string} eg. "foo=bar&baz"
 */
function encode_query_params(params) {
    /** @type {string[]} */
    const parts = []
    for(const [k, v] of Object.entries(params)) {
        if(v === null) {
            parts.push(encodeURIComponent(k))
        } else {
            parts.push(
                encodeURIComponent(k) + "=" + encodeURIComponent(v)
            )
        }
    }
    if(parts.length) {
        return `?${parts.join("&")}`
    } else {
        return ""
    }
}

/**
 *
 * @param {string} href
 * @param {{[x: string]: ?string}} params
 */
function add_params(href, params) {
    const a = document.createElement("a")
    a.href = href
    a.search = encode_query_params({
        ...decode_query_params(a.search),
        ...params,
    })
    return a.href
}
